<template>
  <div class="app-management">
    <div class="btns">
      <el-button @click="addApp">创建分组</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border>
        <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="_id"
            label="分组ID"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="groupName"
            label="分组名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="appList"
            label="组内应用"
            align="center"
        >
          <template slot-scope="{row}">
            <span v-for="(item,index) of row.appInfo" :key="item._id" v-html="item.name + (index +1 !== row.appList.length?' / ':'')"></span>
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            :formatter="(row)=>dateFun.getCurrentDate(true, ['-', ':'], row.createTime)"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.status === 1" size="mini" type="success" @click="changeStatus(scope.row)">正常</el-button>
            <el-button v-else size="mini" type="warning" @click="changeStatus(scope.row)">停用</el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
        >
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="editRow(row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteRow(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    -->
    <el-dialog
        :title="isEdit?'编辑':'添加'"
        :visible.sync="dialogVisible"
        width="500px"
    >
      <div class="edit-form">
        <el-form label-position="left" label-width="80px" :model="editForm" :rules="rules" ref="ruleForm">
          <el-form-item label="分组名称" prop="groupName">
            <el-input v-model="editForm.groupName"></el-input>
          </el-form-item>
          <el-form-item label="选择应用" prop="appList">
            <el-select v-model="editForm.appList" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="item in appList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio v-model="editForm.status" :label="1">启用</el-radio>
            <el-radio v-model="editForm.status" :label="0">停用</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editAppSubmit">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {createAppGroup, deleteAppGroup, getAppGroupList, updateAppGroup} from "@/api/appGroup";
import {getAppList} from "@/api/base";
import dateFun from "@/utils/dateFun";

export default {
  name: "appManagement",
  computed: {
    dateFun() {
      return dateFun
    }
  },
  data() {
    return {
      tableData: [],
      appList:[],
      dialogVisible:false,
      isEdit:false,
      editForm:{
        status: 1
      },
      rules:{
        groupName: { required: true, message: '请添加应用分组名称', trigger: 'change' },
        appList: { required: true, message: '请添选择分组应用', trigger: 'change' },
      }
    }
  },
  mounted() {
    this.getAppList()
    this.getList()
  },
  methods: {
    getList() {
      getAppGroupList().then(res => {
        this.tableData = res.data
      })
    },
    getAppList() {
      getAppList().then(res => {
        this.appList = res.data.map(item=>{
          return {
            label: item.name,
            value: item._id
          }
        })
      })
    },
    deleteRow(row) {
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAppGroup({_id:row._id}).then(res=>{
          if(res.code === 0){
            this.$message({
              message:'删除成功',
              type: 'success'
            });
            this.getList()
          }else{
            this.$message({
              message:'删除失败',
              type: 'warning'
            });
          }
        })
      })
    },
    changeStatus(row){
      updateAppGroup({_id:row._id,status: row.status===1?0:1}).then(res=>{
        if(res.code === 0){
          this.$message({
            message:'操作成功',
            type: 'success'
          });
          this.getList()
        }
      })
    },
    addApp(){
      this.isEdit = false
      this.dialogVisible = true
    },
    editRow(row){
      this.isEdit = true
      this.editForm = {
        _id: row._id,
        groupName:row.groupName,
        status: row.status,
        appList: row.appList
      }
      this.dialogVisible = true
    },
    editAppSubmit(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.isEdit){
            updateAppGroup(this.editForm).then(res=>{
              this.dialogVisible = false
              if(res.code === 0){
                this.$message({
                  message:'编辑成功',
                  type: 'success'
                });
                this.getList()
              }else{
                this.$message({
                  message:'编辑失败',
                  type: 'warning'
                });
              }
            })
          }else{
            createAppGroup(this.editForm).then(res=>{
              if(res.code === 0){
                this.$message({
                  message:'添加成功',
                  type: 'success'
                });
                this.getList()
              }else{
                this.$message({
                  message:'添加失败',
                  type: 'warning'
                });
              }
            })
          }
          this.dialogVisible = false
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.btns {
  margin-bottom: 20px;
}
</style>
