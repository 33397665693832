import service from "@/utils/ajax"

export const getAppGroupList = (params)=>{
    return service({
        url:"/v1/appGroup/list",
        method:"get",
        params
    })
}

export const createAppGroup = (data)=>{
    return service({
        url:"/v1/appGroup/create",
        method:"post",
        data
    })
}

export const updateAppGroup = (data)=>{
    return service({
        url:"/v1/appGroup/update",
        method:"post",
        data
    })
}
export const deleteAppGroup = (data)=>{
    return service({
        url:"/v1/appGroup/delete",
        method:"post",
        data
    })
}
